<template>
  <div class="home_page">
    <div class="block_1">
      <b-row class="align-items-center">
        <b-col
          cols="12"
          lg="6"
        >
          <div class="left_side">
            <h1 class="h1">
              Постійний потік замовлень на розміщення в Telegram-каналі
            </h1>
            <h3>
              <b-badge variant="warning">
                Понад {{ $options.filters.numberFormat(home.web.u) }} замовників
              </b-badge>
              <br>
              готові платити за вашу Telegram-аудиторію
            </h3>
            <b-row>
              <b-col
                cols="12"
                sm="4"
              >
                <b-card class="text-center">
                  <b-avatar
                    class="mb-1"
                    variant="light-warning"
                    size="45"
                  >
                    <feather-icon
                      size="21"
                      icon="ShoppingBagIcon"
                    />
                  </b-avatar>
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ $options.filters.numberFormat(home.web.t) }} замовлень
                    </h2>
                    <span>щодня з'являються на платформі</span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-card class="text-center">
                  <b-avatar
                    class="mb-1"
                    variant="light-warning"
                    size="45"
                  >
                    <feather-icon
                      size="21"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      70 000 грн
                    </h2>
                    <span>середній заробіток на інтеграцій у каналах</span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-card class="text-center">
                  <b-avatar
                    class="mb-1"
                    variant="light-warning"
                    size="45"
                  >
                    <feather-icon
                      size="21"
                      icon="StarIcon"
                    />
                  </b-avatar>
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ $options.filters.numberFormat(home.web.p) }}
                    </h2>
                    <span>нових проектів на платформі щотижня</span>
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <div class="btn_c">
              <b-button
                v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
                variant="primary"
                size="lg"
                :to="!userData ? `/login` : `/account/channels/create`"
                class="mb-2"
              >
                Додати канал
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card>
            <div class="main_video">
              <video
                autoplay="autoplay"
                height="100%"
                loop="loop"
                muted="muted"
                preload="auto"
                width="100%"
              >
                <source
                  src="/video/home.mp4"
                  type="video/mp4"
                >
                Your browser does not support the video tag.
              </video>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div class="block_2">
      <b-row>
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            Як ми працюємо
          </h3>
          <h4>
            Нам важливо, щоб робота на платформі була прозорою як для замовників, так і для власників каналу.
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="7"
        >
          <app-timeline>
            <app-timeline-item icon="UserPlusIcon">
              <div class="d-flex flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Додаєте канал до каталогу</h3>
                <h4>У середньому модерація займає 24 години.</h4>
              </div>
            </app-timeline-item>
            <app-timeline-item icon="ListIcon">
              <div class="d-flex flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Отримуєте замовлення та розміщуєте пости</h3>
                <h4>Оповіщення про нові розміщення приходять на Email та в Telegram-бота</h4>
              </div>
            </app-timeline-item>
            <app-timeline-item icon="DollarSignIcon">
              <div class="d-flex flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Отримуєте оплату</h3>
                <h4>Ви зможете виводити кошти від 1 000 грн</h4>
              </div>
            </app-timeline-item>
          </app-timeline>
          <b-button
            v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
            variant="warning"
            size="lg"
            :to="!userData ? `/login` : `/account/channels/create`"
            class="mt-2 mb-2"
          >
            Додати канал
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="5"
        >
          <div class="video">
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              frameborder="0"
              height="100%"
              src="https://www.youtube.com/embed/WD25Fi74fv8"
              width="100%"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="block_3">
      <h3>
        Як купити інтеграцію у Телеграм з TeLeAds.com.ua
      </h3>
      <b-row>
        <b-col
          cols="12"
          md="1"
        />
        <b-col
          cols="12"
          md="10"
        >
          <b-row>
            <b-col
              cols="12"
              lg="8"
              sm="6"
            >
              <b-card class="text-center">
                <b-avatar
                  class="mb-1"
                  variant="light-warning"
                  size="45"
                >
                  <feather-icon
                    size="21"
                    icon="StarIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    Нам довіряють {{ $options.filters.numberFormat(home.web.c) }} власників
                  </h2>
                  <span>перевірених каналів</span>
                </div>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              lg="4"
              sm="6"
            >
              <b-card class="text-center">
                <b-avatar
                  class="mb-1"
                  variant="light-warning"
                  size="45"
                >
                  <feather-icon
                    size="21"
                    icon="StarIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    Виведення коштів
                  </h2>
                  <span>3 рази на тиждень</span>
                </div>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              lg="4"
              sm="6"
            >
              <b-card class="text-center">
                <b-avatar
                  class="mb-1"
                  variant="light-warning"
                  size="45"
                >
                  <feather-icon
                    size="21"
                    icon="StarIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    Підтримка
                  </h2>
                  <span>7 днів на тиждень</span>
                </div>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              lg="8"
              sm="6"
            >
              <b-card class="text-center">
                <b-avatar
                  class="mb-1"
                  variant="light-warning"
                  size="45"
                >
                  <feather-icon
                    size="21"
                    icon="StarIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    Спілкування тільки з реальними клієнтами
                  </h2>
                  <span>які зробили замовлення</span>
                </div>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              lg="4"
              sm="6"
            >
              <b-card class="text-center">
                <b-avatar
                  class="mb-1"
                  variant="light-warning"
                  size="45"
                >
                  <feather-icon
                    size="21"
                    icon="StarIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    Почнете отримувати
                  </h2>
                  <span>замовлення від великих компаній</span>
                </div>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              lg="4"
              sm="6"
            >
              <b-card class="text-center">
                <b-avatar
                  class="mb-1"
                  variant="light-warning"
                  size="45"
                >
                  <feather-icon
                    size="21"
                    icon="StarIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    Гарантія
                  </h2>
                  <span>чесних угод</span>
                </div>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              lg="4"
              sm="6"
            >
              <b-card class="text-center">
                <b-avatar
                  class="mb-1"
                  variant="light-warning"
                  size="45"
                >
                  <feather-icon
                    size="21"
                    icon="StarIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    Додатковий заробіток
                  </h2>
                  <span>за реферальною програмою</span>
                </div>
              </b-card>
            </b-col>

            <b-col
              cols="12"
              lg="4"
              sm="12"
            />
            <b-col
              cols="12"
              lg="4"
              sm="12"
            >
              <b-button
                v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
                variant="warning"
                block
                size="lg"
                :to="!userData ? `/login` : `/account/channels/create`"
                class="mt-1"
              >
                Додати канал безкоштовно
              </b-button>
            </b-col>
            <b-col
              cols="12"
              lg="4"
              sm="12"
            />
          </b-row>
        </b-col>
        <b-col
          cols="12"
          md="1"
        />
      </b-row>
    </div>

    <div class="block_4">
      <b-row>
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            Отримайте значок «Ексклюзивний канал», щоб <b-badge variant="danger">
              заробити ще більше
            </b-badge>
          </h3>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <app-timeline>
            <app-timeline-item icon="AnchorIcon">
              <div class="d-flex flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Вкажіть в описі вашого каналу своє посилання реферала з платформи</h3>
              </div>
            </app-timeline-item>
            <app-timeline-item icon="AwardIcon">
              <div class="d-flex flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Натомість ми розмістимо вас у фільтрі «Ексклюзивні канали»</h3>
              </div>
            </app-timeline-item>
            <app-timeline-item icon="DollarSignIcon">
              <div class="d-flex flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Заробляйте {{ prc.cr }}% з кожної успішної оплати за вашим посиланням</h3>
              </div>
            </app-timeline-item>
          </app-timeline>
          <b-button
            v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
            variant="warning"
            size="lg"
            :to="!userData ? `/login` : `/account/channels/create`"
            class="mt-2"
          >
            Додати канал
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-img
            fluid
            :src="imgUrl"
          />
        </b-col>
      </b-row>
    </div>

    <div class="block_5">
      <h3>
        Маркетплейс нативних інтеграцій TeLeAds.com.ua: <br>відгуки клієнтів
      </h3>
      <b-row>
        <b-col
          cols="12"
          md="1"
        />
        <b-col
          cols="12"
          md="10"
        >
          <b-carousel
            id="carousel-interval"
            no-hover-pause
            controls
            indicators
            :interval="5000"
          >
            <b-carousel-slide
              v-for="(block, index) in getChunkMessages"
              :key="index"
            >
              <template #img>
                <b-row>
                  <b-col
                    v-for="review in block"
                    :key="review.id"
                    cols="12"
                    md="6"
                  >
                    <b-card>
                      <h6 class="font-weight-bolder mb-25">
                        {{ review.title }}
                      </h6>
                      <b-card-text>
                        {{ review.created_at }}
                      </b-card-text>
                      <b-card-text>
                        {{ review.message }}
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </b-col>
        <b-col
          cols="12"
          md="1"
        />
        <b-col
          cols="12"
          class="p-2 "
        >
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
              variant="primary"
              size="lg"
              :to="`/reviews`"
            >
              Показати всі відгуки
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
/* eslint-disable global-require */
import GlobalMixin from '@mixins/GlobalMixin'
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import store from '@/store'
import _ from 'lodash'
// import router from '@/router'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCarousel,
    BCarouselSlide,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      home: {
        all: {
          u: 0,
          c: 0,
          t: 0,
        },
        m: {
          u: 0,
          c: 0,
          t: 0,
        },
      },
      prc: {
        afmn: 0,
        afmx: 0,
        awmn: 0,
        awmx: 0,
        cf: 0,
        cfm: 0,
        cr: 0,
        cs: 0,
        cw: 0,
        cwm: 0,
        gral: 0,
        grer: 0,
      },
      messages: [],
      downImg: require('@/assets/images/pages/error.svg'),
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    getChunkMessages() {
      return _.chunk(this.messages, 2)
    },
  },
  created() {
    this.fetchHome()
    this.fetchReviews()
  },
  mounted() {
    this.prc = JSON.parse(localStorage.getItem('prc')) ?? {}
  },
  methods: {
    fetchReviews() {
      this.$http.get('/api/promo/messages/', {
        params: {
          per_page: 10,
        },
      })
        .then(response => {
          this.messages = response.data.data
        })
    },
    fetchHome() {
      this.$http.get('/api/promo/homepage/')
        .then(response => {
          this.home = response.data.data
        })
    },
  },
  setup() {
    const { canViewVerticalNavMenuLink } = useAclUtils()
    return {
      canViewVerticalNavMenuLink,
    }
  },
}
</script>

<style lang="scss">
.home_page {
  .content-header {
    display: none;
  }

  .block_1 {
    h1 {
      margin:0 0 1em;
      font-size: 3em;
    }

    h3 {
      margin:0 0 2em;
    }

    @media (max-width: 700px) {
      h1 {
        font-size: 2em;
      }
    }
  }

  .block_2 {
    background: var(--primary);
    margin-top:2em;
    border-radius: 0.428rem;
    padding: 3em;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin:0 0 3em;

      h3 {
        font-size: 3em;
        color: #fff;
        font-weight: bold;
      }
    }

    h3,
    h4 {
      color:#fff;
    }

    .video {
      position: relative;
      padding-bottom: 56%;
      overflow: hidden;
      border-radius: 10px;

      iframe {
        position: absolute;
        left:0;
        top:0;
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: 700px) {
      padding: 1em;

      .title {
        h3 {
          font-size: 2em;
        }
      }
    }
  }

  .block_3 {
    padding: 3em 0;

    h3 {
      font-size: 3em;
      text-align: center;
      display: block;
      margin: 2em 0;
      font-weight: bold;
    }

    .card {
      &.success h1,
      &.success h2,
      &.success h3,
      &.success h4,
      &.success h5,
      &.success h6,
      &.success span,
      &.success p {
        color: #fff !important;
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          gap:1em;
        }

        .right {
          font-size: 2em;
        }
      }

      h4 {
        font-size: 2em;
        font-weight: bold;
        margin: 0 0 2em;
        display: block;
      }
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 2em;
      }
    }
  }

  .block_4 {
    background: #5e5873;
    margin-top:2em;
    border-radius: 0.428rem;
    padding: 3em;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    p {
      color: #fff !important;
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin:0 0 3em;

      h3 {
        font-size: 3em;
        font-weight: bold;
      }
    }

    @media (max-width: 700px) {
      padding: 1em;

      .title {
        margin:0 0 1em;

        h3 {
          font-size: 2em;
        }
      }
    }
  }

  .block_5 {
    padding: 3em 0;

    h3 {
      font-size: 3em;
      text-align: center;
      display: block;
      margin: 2em 0;
      font-weight: bold;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 2em;
      }
    }
  }
}

@import "~@core/scss/base/pages/app-ecommerce.scss";
//@import "https://telega.in/assets/home/application-0aa30cd3fcd937f1f1f57949a12b769010c73736d5e4584cb9ac1cc240f4d635.css";
</style>
